var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"new-and-old-member-statistics"},[_c('div',[_c('TagsChartTitle',{attrs:{"title":"新會員 標籤人數佔比","hint":"比較時間內新會員擁有標籤條件的人數與佔比","filterOptions":_vm.dateRangeConfig,"showBorder":false,"maxTags":10,"currentTags":_vm.currentTags,"updateTags":_vm.updateTags,"tags":_vm.tags,"useRangeType":false}}),_c('div',{staticClass:"total-member"},[_vm._v(_vm._s(`${_vm.chartData.newMember.count}人`))]),_c('ColumnChartBlock',{attrs:{"options":_vm.generateColumnConfig({
          labels: _vm.chartData.newMember.labels,
          series: [{ data: _vm.chartData.newMember.series }],
        })}})],1),_c('div',[_c('ChartTitle',{attrs:{"title":"舊會員 標籤人數佔比","hint":"比較時間內舊會員擁有標籤條件的人數與佔比","showBorder":false,"showOptions":false}}),_c('div',{staticClass:"total-member"},[_vm._v(_vm._s(`${_vm.chartData.oldMember.count}人`))]),_c('ColumnChartBlock',{attrs:{"options":_vm.generateColumnConfig({
          labels: _vm.chartData.oldMember.labels,
          series: [{ data: _vm.chartData.oldMember.series }],
        })}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }