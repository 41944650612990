<template>
  <div class="first-purchase-rate-statistics">
    <div>
      <TagsChartTitle
        title="首購客 標籤人數佔比"
        hint="比較時間內首購客擁有標籤條件的人數與佔比"
        :filterOptions="dateRangeConfig"
        :showBorder="false"
        :maxTags="10"
        :currentTags="currentTags"
        :updateTags="updateTags"
        :tags="tags"
        :getChart="getChartData"
        :useRangeType="true"
        :useRange="true"
      />
      <div class="total-member">{{ `${chartData.firstPurchase.count}人` }}</div>
      <ColumnChartBlock
        :options="
          generateColumnConfig({
            labels: chartData.firstPurchase.labels,
            series: [{ data: chartData.firstPurchase.series }],
          })
        "
      />
    </div>
    <div>
      <ChartTitle
        title="回購客 標籤人數佔比"
        hint="比較時間內回購客擁有標籤條件的人數與佔比"
        :showBorder="false"
        :showOptions="false"
      />
      <div class="total-member">{{ `${chartData.rePurchase.count}人` }}</div>
      <ColumnChartBlock
        :options="
          generateColumnConfig({
            labels: chartData.rePurchase.labels,
            series: [{ data: chartData.rePurchase.series }],
          })
        "
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, ref, reactive } from 'vue'
import ChartTitle from '@/components/Dashboard/ChartTitle.vue'
import TagsChartTitle from '@/components/Dashboard/TagsChartTitle.vue'
import { dateRangeConfig } from '@/config/dashboard'
import ColumnChartBlock from '@/components/Dashboard/ColumnChartBlock.vue'
import { useTagStore } from '@/components/Dashboard/useTagStore'
import store from '@/store'
import { getFirstPurchaseRateData, generateColumnConfig } from '../../common'

export default defineComponent({
  name: 'FirstPurchaseRateStatistics',
  components: { TagsChartTitle, ChartTitle, ColumnChartBlock },
  setup () {
    const { tags, getAllTags } = useTagStore()
    const shopId = computed(() => store.getters.shop)
    const currentTags = ref([])
    const chartData = reactive({
      firstPurchase: {},
      rePurchase: {},
    })

    const getChartData = async ({ interval, splitType }) => {
      const res = await getFirstPurchaseRateData({
        currentTags: currentTags.value,
        shopId: shopId.value,
        interval,
        splitType,
        allTags: tags.value,
      })
      chartData.firstPurchase = res.firstPurchase
      chartData.rePurchase = res.rePurchase
    }

    const updateTags = async (e) => {
      currentTags.value = e
      getChartData({
        currentTags: e,
      })
    }

    // const getChart = async ({ interval }) => {
    //   const res = await getFirstPurchaseRateData({
    //     currentTags: currentTags.value,
    //     shopId: shopId.value,
    //     interval,
    //     allTags: tags.value,
    //   })
    //   chartData.firstPurchase = res.firstPurchase
    //   chartData.rePurchase = res.rePurchase
    // }

    const getDefaultTags = () => {
      const defaultKey = [
        '銀卡',
        '完成至少一筆預約',
        '註冊90天內',
        '年度消費0~20仟元',
      ]

      const userInfoAgeGroup = tags.value.filter(
        (tag) => tag.systemGroup === 'userInfoAge',
      )

      const defaultTags = tags.value.filter((tag) => {
        return defaultKey.includes(tag.name)
      })

      return [userInfoAgeGroup[0].id, ...defaultTags.map((tag) => tag.id)]
    }

    onMounted(async () => {
      await getAllTags()

      const defaultTags = getDefaultTags()

      getChartData({
        currentTags: defaultTags.length ? defaultTags : [],
      })

      if (defaultTags.length) {
        currentTags.value = defaultTags
      }
    })

    return {
      chartData,
      dateRangeConfig,
      tags,
      currentTags,
      updateTags,
      generateColumnConfig,
      getChartData,
    }
  },
})
</script>

<style lang="postcss" scoped>
.member-proportion-statistics {
  @apply flex flex-col gap-5;
}
.total-member {
  @apply text-[28px] text-primary-100;
  @apply pb-5;
  @apply border-b border-[#ececec];
}
</style>
