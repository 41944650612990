var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"first-purchase-rate-statistics"},[_c('div',[_c('TagsChartTitle',{attrs:{"title":"首購客 標籤人數佔比","hint":"比較時間內首購客擁有標籤條件的人數與佔比","filterOptions":_vm.dateRangeConfig,"showBorder":false,"maxTags":10,"currentTags":_vm.currentTags,"updateTags":_vm.updateTags,"tags":_vm.tags,"getChart":_vm.getChartData,"useRangeType":true,"useRange":true}}),_c('div',{staticClass:"total-member"},[_vm._v(_vm._s(`${_vm.chartData.firstPurchase.count}人`))]),_c('ColumnChartBlock',{attrs:{"options":_vm.generateColumnConfig({
          labels: _vm.chartData.firstPurchase.labels,
          series: [{ data: _vm.chartData.firstPurchase.series }],
        })}})],1),_c('div',[_c('ChartTitle',{attrs:{"title":"回購客 標籤人數佔比","hint":"比較時間內回購客擁有標籤條件的人數與佔比","showBorder":false,"showOptions":false}}),_c('div',{staticClass:"total-member"},[_vm._v(_vm._s(`${_vm.chartData.rePurchase.count}人`))]),_c('ColumnChartBlock',{attrs:{"options":_vm.generateColumnConfig({
          labels: _vm.chartData.rePurchase.labels,
          series: [{ data: _vm.chartData.rePurchase.series }],
        })}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }