<template>
  <div class="new-and-old-member-statistics">
    <div>
      <TagsChartTitle
        title="新會員 標籤人數佔比"
        hint="比較時間內新會員擁有標籤條件的人數與佔比"
        :filterOptions="dateRangeConfig"
        :showBorder="false"
        :maxTags="10"
        :currentTags="currentTags"
        :updateTags="updateTags"
        :tags="tags"
        :useRangeType="false"
      />
      <div class="total-member">{{ `${chartData.newMember.count}人` }}</div>
      <ColumnChartBlock
        :options="
          generateColumnConfig({
            labels: chartData.newMember.labels,
            series: [{ data: chartData.newMember.series }],
          })
        "
      />
    </div>
    <div>
      <ChartTitle
        title="舊會員 標籤人數佔比"
        hint="比較時間內舊會員擁有標籤條件的人數與佔比"
        :showBorder="false"
        :showOptions="false"
      />
      <div class="total-member">{{ `${chartData.oldMember.count}人` }}</div>
      <ColumnChartBlock
        :options="
          generateColumnConfig({
            labels: chartData.oldMember.labels,
            series: [{ data: chartData.oldMember.series }],
          })
        "
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, ref, reactive } from 'vue'
import ChartTitle from '@/components/Dashboard/ChartTitle.vue'
import TagsChartTitle from '@/components/Dashboard/TagsChartTitle.vue'
import { dateRangeConfig } from '@/config/dashboard'
import ColumnChartBlock from '@/components/Dashboard/ColumnChartBlock.vue'
import { useTagStore } from '@/components/Dashboard/useTagStore'
import store from '@/store'
import { getNewAndOldMemberData, generateColumnConfig } from '../../common'

export default defineComponent({
  name: 'NewAndOldMemberStatistics',
  components: { ChartTitle, TagsChartTitle, ColumnChartBlock },
  setup () {
    const { tags, getAllTags } = useTagStore()
    const shopId = computed(() => store.getters.shop)
    const currentTags = ref([])
    const chartData = reactive({
      newMember: {},
      oldMember: {},
    })

    const getChartData = async ({ currentTags }) => {
      const res = await getNewAndOldMemberData({
        currentTags,
        shopId: shopId.value,
        dateRange: 30,
        allTags: tags.value,
      })
      chartData.newMember = res.newMember
      chartData.oldMember = res.oldMember
    }

    const updateTags = async (e) => {
      currentTags.value = e
      getChartData({
        currentTags: e,
      })
    }

    // const getChart = async ({ interval }) => {
    //   const res = await getNewAndOldMemberData({
    //     currentTags: currentTags.value,
    //     shopId: shopId.value,
    //     interval,
    //     allTags: tags.value,
    //   })
    //   chartData.newMember = res.newMember
    //   chartData.oldMember = res.oldMember
    // }

    const getDefaultTags = () => {
      const defaultKey = [
        '銀卡',
        '完成至少一筆預約',
        '註冊90天內',
        '年度消費0~20仟元',
      ]
      const userInfoAgeGroup = tags.value.filter(
        (tag) => tag.systemGroup === 'userInfoAge',
      )

      const defaultTags = tags.value.filter((tag) => {
        return defaultKey.includes(tag.name)
      })

      return [userInfoAgeGroup[0].id, ...defaultTags.map((tag) => tag.id)]
    }

    onMounted(async () => {
      await getAllTags()

      const defaultTags = getDefaultTags()

      getChartData({
        currentTags: defaultTags.length ? defaultTags : [],
      })

      if (defaultTags.length) {
        currentTags.value = defaultTags
      }
    })

    return {
      chartData,
      dateRangeConfig,
      tags,
      currentTags,
      updateTags,
      generateColumnConfig,
    }
  },
})
</script>

<style lang="postcss" scoped>
.new-and-old-member-statistics {
  @apply flex flex-col gap-5;
}
.total-member {
  @apply text-[28px] text-primary-100;
  @apply pb-5;
  @apply border-b border-[#ececec];
}
</style>
