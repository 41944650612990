<template>
  <div class="member-age-statistics">
    <ChartTitle title="會員年齡分佈" :showOptions="false" />
    <div ref="chartRef" class="chart" />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted } from 'vue'
import { dateRangeConfig } from '@/config/dashboard'
import ChartTitle from '@/components/Dashboard/ChartTitle.vue'
import store from '@/store'
import { useChart } from '@/use/dashboard'
import { generateMemberAgeConfig, getAgeData } from '../../common'

export default defineComponent({
  name: 'MemberAgeStatistics',
  components: { ChartTitle },
  emits: ['getTime'],
  setup (props, { emit }) {
    const { chartRef, updateChart } = useChart(generateMemberAgeConfig())
    const shopId = computed(() => store.getters.shop)

    onMounted(async () => {
      const { newOptions, lastUpdatedAt } = await getAgeData({
        shopId: shopId.value,
      })

      updateChart(newOptions)
      emit('getTime', lastUpdatedAt)
    })

    return {
      chartRef,
      dateRangeConfig,
    }
  },
})
</script>

<style lang="postcss" scoped>
.member-age-statistics {
  @apply flex flex-col gap-5;
}
</style>
