<template>
  <div class="column-chart-block">
    <div ref="chartRef" class="chart" />
  </div>
</template>

<script>
import { defineComponent, watch } from 'vue'
import { useChart } from '@/use/dashboard'

export default defineComponent({
  name: 'ColumnChartBlock',
  props: {
    options: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const { chartRef, updateChart } = useChart(props.options)

    watch(
      () => props.options,
      () => {
        updateChart(props.options)
      },
    )
    return {
      chartRef,
    }
  },
})
</script>
