<template>
  <div class="member-proportion-statistics">
    <div class="flex justify-center">
      <DonutChartBlock
        :options="chartData.firstPurchase"
        title="首購客與回購客人數佔比"
        :subtitle="`${chartData.firstPurchaseCount}人`"
        class="flex-1"
      />
      <DonutChartBlock
        :options="chartData.gender"
        title="性別人數佔比"
        :subtitle="`${chartData.genderCount}人`"
        class="flex-1"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, reactive, onMounted } from 'vue'
import { dateRangeConfig } from '@/config/dashboard'
import DonutChartBlock from '@/components/Dashboard/DonutChartBlock.vue'
import store from '@/store'
import { truncateDecimal, getChartData } from '@/utils/dashboard'
import {
  generateMemberProportionConfig,
  getMemberProportionData,
} from '../../common'

export default defineComponent({
  name: 'MemberProportionStatistics',
  components: { DonutChartBlock },
  setup () {
    const shopId = computed(() => store.getters.shop)
    const chartData = reactive({
      firstPurchase: generateMemberProportionConfig(),
      firstPurchaseCount: 0,
      gender: generateMemberProportionConfig(),
      genderCount: 0,
    })

    const getFirstPurchase = async () => {
      const { result } = await getChartData({
        shopId: shopId.value,
        key: 'firstPurchaseRate',
      })

      const labels = result.map((item) => item.transaction_status)
      const series = result.map((item) => item.count)

      chartData.firstPurchase = generateMemberProportionConfig(labels, series)
      chartData.firstPurchaseCount = series.reduce((prev, curr) => {
        return prev + curr
      }, 0)
    }

    const getGender = async () => {
      const { result } = await getChartData({
        shopId: shopId.value,
        key: 'memberGenderRate',
      })

      const labels = ['男', '女', '其他']
      const series = [
        result.find(({ gender }) => gender === 'male').count,
        result.find(({ gender }) => gender === 'female').count,
        result.find(({ gender }) => gender === null).count,
      ]
      chartData.gender = generateMemberProportionConfig(labels, series)
      chartData.genderCount = series.reduce((prev, curr) => {
        return prev + curr
      }, 0)
    }

    onMounted(async () => {
      Promise.allSettled([getFirstPurchase(), getGender()])
    })

    return {
      chartData,
      dateRangeConfig,
    }
  },
})
</script>

<style lang="postcss" scoped>
.member-proportion-statistics {
  @apply flex flex-col gap-5;
}
</style>
