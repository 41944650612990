<template>
  <div class="member-tag-statistics">
    <TagsChartTitle
      title="新註冊會員的標籤人數趨勢"
      hint="比較時間內所有新註冊的會員中，擁有條件標籤的人數"
      :filterOptions="dateRangeConfig"
      :maxTags="3"
      :currentTags="currentTags"
      :updateTags="updateTags"
      :tags="tags"
      :getChart="getChart"
      :useRange="true"
    />
    <div ref="chartRef" class="chart" />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue'
import { dateRangeConfig } from '@/config/dashboard'
import TagsChartTitle from '@/components/Dashboard/TagsChartTitle.vue'
import { useTagStore } from '@/components/Dashboard/useTagStore'
import store from '@/store'
import { useChart } from '@/use/dashboard'
import { generateNewMemberTagsConfig, getNewMemberTagsData } from '../../common'

export default defineComponent({
  name: 'MemberTagStatistics',
  components: { TagsChartTitle },
  setup () {
    const { chartRef, updateChart } = useChart(generateNewMemberTagsConfig())
    const { tags, getAllTags } = useTagStore()
    const shopId = computed(() => store.getters.shop)
    const currentTags = ref([])

    const updateTags = async (e) => {
      currentTags.value = e
    }

    const getChart = async ({ interval, splitType }) => {
      const res = await getNewMemberTagsData({
        interval,
        splitType,
        shopId: shopId.value,
        currentTags: currentTags.value,
        allTags: tags.value,
      })

      updateChart(res)
    }

    onMounted(async () => {
      await getAllTags()

      const defaultTags = tags.value.find((tag) => {
        return tag.systemName === 'memberConfig:level:1'
      })

      if (defaultTags) {
        currentTags.value = [defaultTags.id]
      }
    })
    return {
      chartRef,
      dateRangeConfig,
      currentTags,
      updateTags,
      tags,
      getChart,
    }
  },
})
</script>

<style lang="postcss" scoped>
.member-tag-statistics {
  @apply flex flex-col gap-5;
}
</style>
